<template>
  <div class="card-course flex row items-center">
    <BadgeType 
      typeBadge="Conhecimento"
      iconBadge="book"
      class="badge-icon"
    />
    <h5 class="col-8">
      {{title}}
    </h5>
    <router-link :to="{ name: 'training', query:{id: idCourse} }">
      <q-img  class="action-btn q-mr-md" src="../../../assets/icons/access.svg" fit spinner-color="white" />
    </router-link>
    <router-link target="_blank" :to="{ name: 'certificate', query: { courseId: idCourse } }">
      <q-img width="23px" src="../../../assets/icons/certificate.svg" fit spinner-color="white" />
    </router-link>

  </div>
</template>

<script>
//----COMPONENTS
import BadgeType from '@/components/BadgeType.vue';

export default {
  name: 'CardLastCourses',
  components: {
    BadgeType
  }, 
  props:{
    title:String,
    idCourse:Number
  }
}
</script>

<style lang="scss" scoped>
.card-course{
  width: 100%;
  padding: 8px;
  background: #FFFFFF;
  /* Light/Grey 1 */

  border: 1px solid #E5E5E5;
  box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.039), 0px 0.85px 3px rgba(0, 0, 0, 0.19);
  border-radius: 8px;

  .badge-icon{
    width: 35px;
    height: 35px;
  }

  h5{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    /* Light/Grey5 */
    color: #2F2F2F;
  }

  .action-btn{
    width: 18px;
    height: auto;
  }
}
</style>
